import { ref, watch, onMounted, onBeforeUnmount, computed } from "vue";
import { useStore } from "vuex";

export default function useVideoObserver(videoRef, observeRef) {
  const videoIsInView = ref(false);
  const store = useStore();

  const menuOpen = computed(() => store.state.modifiers.drawerOpen);
  const hasObserver = computed(
    () => typeof window.IntersectionObserver !== "undefined"
  );

  watch(menuOpen, (val) => pauseObserver(val));
  watch(videoIsInView, (val) => toggleVideo(!val));

  const pauseObserver = (pause) => {
    if (!observer.value) {
      toggleVideo(pause);
    } else if (pause) {
      observer.value.unobserve(videoRef.value);
      toggleVideo(pause);
    } else {
      observer.value.observe(observeRef.value);
    }
  };

  const toggleVideo = (pause) => {
    if (!videoRef.value) return;
    if (pause) {
      videoRef.value.pause();
    } else if (videoRef.value.paused) {
      videoRef.value.play();
    }
  };

  const observer = ref(null);

  onMounted(() => {
    if (hasObserver.value) {
      observer.value = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log("%c%s", "color:darkseagreen;", "video in view");
            videoIsInView.value = true;
            toggleVideo(false);
          } else {
            console.log("%c%s", "color:olive;", "video not in view");
            videoIsInView.value = false;
            toggleVideo(true);
          }
        });
      });

      observer.value.observe(observeRef.value);
    }
  });

  onBeforeUnmount(() => {
    if (observer.value) {
      observer.value.disconnect();
    }
  });

  return {
    videoRef,
    observeRef,
    videoIsInView,
    menuOpen,
    hasObserver,
    pauseObserver,
    toggleVideo,
  };
}
