<template>
  <div
    :id="`patterns-${pulseOrder}`"
    :class="[`mix-blend--${blend}`, { desaturate }]"
    class="pattern-overlay absolute inset-0 z-20 mix-blend pointer-events-none slow-pulse"
  >
    <div class="pattern-overlay__side pattern-overlay__side__left">
      <img
        :src="$constants.images.pattern.banner"
        :style="`opacity: ${opacity};`"
        alt=""
      />
    </div>
    <div class="pattern-overlay__side pattern-overlay__side__right">
      <img
        :src="$constants.images.pattern.banner"
        :style="`opacity: ${opacity};`"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "LondoPatternOverlay",
  data() {
    return {};
  },
  props: {
    blend: {
      type: String,
      default: "normal",
    },
    pulseOrder: {
      type: Number,
      default: 1,
    },
    opacity: {
      type: String,
      default: "0.2",
    },
    desaturate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.pattern-overlay {
  --pattern-distance: -40vh;
  --slow-pulse-duration: 12s;
  animation: slow-pulse var(--slow-pulse-duration) infinite;
  &.desaturate {
    filter: saturate(0);
  }
  &__side {
    @apply absolute h-screen flex;
    img {
      height: 100%;
      max-width: none;
      width: auto;
    }
    &__left {
      left: var(--pattern-distance);
    }
    &__right {
      @apply justify-end;
      right: var(--pattern-distance);
      img {
        transform: scaleX(-1);
      }
    }
  }
  &#patterns-1 {
  }
  &#patterns-2 {
    animation-delay: calc(var(--slow-pulse-duration) / 2);
  }
}

@media screen and (orientation: portrait) {
  .banner__image__pattern {
    .pattern-overlay__side {
      bottom: -10vh;
    }
  }
}

@screen xs {
  .pattern-overlay {
    --pattern-distance: -38vh;
  }
}

@screen sm {
  .pattern-overlay {
    --pattern-distance: -35vh;
  }
}

@screen md {
  .pattern-overlay {
    --pattern-distance: -35vh;
  }
}

@screen lg {
  .pattern-overlay {
    --pattern-distance: -22vh;
  }
}

@screen xl {
  .pattern-overlay {
    --pattern-distance: -18vh;
  }
}

@screen xxl {
  .pattern-overlay {
    --pattern-distance: -17vh;
  }
}
</style>
