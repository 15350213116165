<!-- eslint-disable max-len -->
<template>
  <div
    class="footer relative overflow-hidden w-full"
    ref="footer"
    :class="{ 'footer--in-view': videoIsInView }"
  >
    <div
      class="footer__video h-screen w-full fixed inset-0 bg-black"
      v-if="footerData"
    >
      <video
        :autoplay="!hasObserver"
        :preload="!device.mobile"
        loop
        muted
        playsinline
        class="object-cover object-bottom h-full w-full z-0"
        ref="videoFooter"
        poster="https://cdn.londolozi.com/wp-content/uploads/2020/08/footer-new-poster.jpg"
      >
        <source
          src="https://cdn.londolozi.com/wp-content/uploads/2020/08/footer-new.mp4"
          type="video/mp4"
        />
      </video>
      <div class="footer__video__overlay absolute inset-0 z-20 hidden"></div>
    </div>
    <div v-if="false" class="footer__background inset-0 fixed z-0">
      <transition name="fade">
        <img
          v-if="screen.portrait"
          :srcset="footerData.media.image_background.srcset"
          class="object-cover h-screen w-full object-bottom"
          alt="footer-background"
          key="1"
        />
        <img
          v-else
          :srcset="footerData.media.image_background_landscape.srcset"
          class="object-cover h-screen w-full object-bottom"
          alt="footer-background-landscape"
          key="2"
        />
      </transition>
      <div class="overlay absolute inset-0"></div>
    </div>
    <div
      class="footer__content relative z-10 text-white font-dinwide leading-4 lg:leading-5 capitalize text-base lg:text-base fade-up fade-out"
      v-if="footerData"
    >
      <div
        class="w-full mx-auto flex flex-col items-center justify-center min-h-screen pt-16 pb-6 width-lg"
      >
        <div class="flex flex-wrap w-full mt-auto justify-center">
          <div
            class="blocks__block py-3 px-4 w-1/2 md:w-1/3 lg:w-1/4"
            v-for="(block, index) in footerData.footer.blocks"
            :key="index"
          >
            <div class="flex flex-col">
              <h6 class="trajan text-grey text-xl flex py-3">
                <global-link
                  v-if="routeNameToPath[parentIdToIndexRouteName[block.id]]"
                  :to="
                    '/' + routeNameToPath[parentIdToIndexRouteName[block.id]]
                  "
                  class="flex-intial a-h6"
                >
                  {{ block.title }}
                </global-link>
                <span v-else>{{ block.title }}</span>
              </h6>
              <global-link
                v-for="(link, linkIndex) in blockLinksWithMappedRoute(
                  block.links
                )"
                :key="linkIndex"
                :to="childRoute(link).path"
                class="py-1 mb-1 overflow-ellipsis a-text"
                >{{ childRoute(link).title }}</global-link
              >
            </div>
          </div>
          <div class="londolozi-live px-4 w-full md:w-2/3 lg:w-1/2 py-3">
            <h6 class="trajan text-grey text-xl flex py-3">Londolozi Live</h6>
            <div class="londolozi-live__links flex">
              <a
                class="flex-grow flex-col text-center w-auto xs:w-1/5"
                v-for="(live, liveIndex) in footerData.footer.londolozi_live"
                :key="liveIndex"
                :href="live.url"
              >
                <i
                  :class="`icon-${lowerCaseNoSpace(live.title)}`"
                  class="text-grey-400 text-3xl py-2 my-1 lg:my-2 opacity-75 block"
                ></i>
                <div class="pt-1">{{ live.title }}</div>
              </a>
            </div>
          </div>
        </div>
        <div class="flex w-full flex-wrap justify-center mt-16 lg:mt-4">
          <div class="footer__logo flex justify-center px-4 py-4 mb-1 w-full">
            <NuxtLink to="/">
              <img
                :src="$constants.images.logo.svg.white"
                class=""
                alt="londolozi logo"
              />
            </NuxtLink>
          </div>
          <div
            class="flex flex-col w-full px-4 sm:w-2/3 md:w-2/3 lg:w-1/2 py-4 mb-4"
          >
            <hr />
            <div class="socials flex">
              <a
                class="social px-4 py-5 text-2xl leading-none flex flex-auto justify-center items-center opacity-75"
                v-for="(social, socialIndex) in footerData.social.socials"
                :key="socialIndex"
                :href="social.url"
                :title="social.title"
                :class="`icon-${social.title.toLowerCase()}`"
                target="_blank"
              >
              </a>
            </div>
            <hr />
            <div class="associations flex">
              <a
                class="association p-4 opacity-75 flex-auto flex justify-center"
                v-for="(ass, index) in footerData.footer.associations"
                :key="index"
                :href="ass.url"
                target="_blank"
              >
                <img
                  class="opacity-75"
                  :src="ass.image.url"
                  :alt="ass.image.alt"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="copyright mt-auto din w-full">
          <hr />
          <h6 class="din text-center pt-6 opacity-75">
            &copy; LONDOLOZI {{ currentYear }} - ALL RIGHTS RESERVED
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useDevice } from "@/composables/useDevice";
import pageMapping, {
  parentIdToChildRouteName,
  parentIdToIndexRouteName,
  routeNameToPath,
} from "@/util/pageMapping";
import useVideoObserver from "@/composables/useVideoObserver";

export default {
  name: "LondoFooter",
  setup() {
    const store = useStore();

    const device = useDevice();
    const loading = ref(true);
    const footer = ref(null);
    const videoFooter = ref(null);
    const currentYear = ref(new Date().getFullYear());
    const {
      videoIsInView,
      hasObserver,
      pauseObserver,
      toggleVideo,
    } = useVideoObserver(videoFooter, footer);

    const footerData = computed(() => store.state.global.footer);

    function blockLinksWithMappedRoute(blockLinks) {
      return blockLinks.filter((bl) => {
        if (!store.state.global.pageMapping) {
          return false;
        }

        const page = store.state.global.pageMapping[bl];

        if (!page) {
          return false;
        }

        return true;
      });
    }

    function childRoute(link) {
      const COMPARE_CAMPS = "compare-camps";
      const ENQUIRE_NOW = "enquire";

      const page = store.state.global.pageMapping[link];

      if (!page) {
        return null;
      }

      const { slug, parent, title } = page;

      if (slug === COMPARE_CAMPS) {
        return {
          title,
          name: pageMapping.compare,
          path: `/accommodation/compare-camps`,
        };
      }

      if (slug === ENQUIRE_NOW) {
        return {
          title,
          name: "Enquire",
          path: `/${slug}`,
        };
      }

      return {
        title,
        name: parentIdToChildRouteName[parent],
        path: `/${routeNameToPath[parentIdToChildRouteName[parent]]}/${slug}`,
      };
    }

    function lowerCaseNoSpace(val) {
      if (!val) return "";
      return val.toString().toLowerCase().replace(" ", "");
    }

    return {
      loading,
      device,
      currentYear,
      footer,
      footerData,
      videoFooter,
      parentIdToIndexRouteName,
      blockLinksWithMappedRoute,
      childRoute,
      lowerCaseNoSpace,
      videoIsInView,
      pauseObserver,
      toggleVideo,
      hasObserver,
      routeNameToPath,
    };
  },
};
</script>

<style lang="scss" scoped>
.overflow-ellipsis {
  @apply inline-block overflow-hidden max-w-full whitespace-nowrap;
  text-overflow: ellipsis;
}
.footer {
  &__logo {
    opacity: 0.9;
    img {
      max-width: 50vw;
      width: 250px;
      filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.8));
    }
  }
  &__content {
    @apply px-2;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
    background: radial-gradient(
        ellipse at center,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.05) 20%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      linear-gradient(
        to bottom,
        rgba(3, 13, 30, 0.6) 0%,
        rgba(3, 13, 30, 0.45) 100%
      );
    h6 {
      &:after {
        @apply flex-auto ml-3;
        margin-bottom: 0.4em;
        content: "";
        border-bottom: 1px solid rgba(74, 85, 102, 0.4);
        box-shadow: 0 1px 0px rgba(0, 0, 0, 0.3);
      }
      a {
        @include transition-base;
        &:hover {
          @apply text-grey-400;
          text-shadow: 0px 1px 2px rgba(55, 152, 251, 0.5);
        }
      }
    }
    hr {
      border-top: 1px solid rgba(74, 85, 102, 0.4);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    }
    .londolozi-live {
      a {
        min-width: 16%;
        i {
          @include transition-base;
          @apply flex justify-center;
        }
        div {
          @include transition-base;
        }
        &:hover {
          i {
            @include transition-base;
            color: #3798fb;
          }
          div {
            @apply text-grey-200;
            text-shadow: 0px 1px 2px #3798fb;
          }
        }
      }
    }
    .associations {
      a {
        img {
          @apply self-start;
          @include transition-base;
          max-height: 106px;
        }
        &:hover {
          img {
            opacity: 1;
          }
        }
      }
    }
    .blocks__block a.a-text {
      @include transition-base;
      &:hover {
        @apply text-grey-200;
        text-shadow: 0px 1px 2px #3798fb;
      }
    }
    .londolozi-live a {
      a {
        @include transition-base;
        &:hover {
          @apply text-grey-200;
          @include transition-base;
          text-shadow: 0px 1px 2px #3798fb;
          i {
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
  }
  .footer__background {
    .overlay {
      background: rgba(0, 0, 0, 0.5);
    }
  }
  &__video {
    z-index: -1;
    transform: translate3d(0, 0, 0);
    &__overlay {
    }
  }
  &--in-view {
    .footer__video {
    }
  }
}

.ios,
.ipad {
  .h-screen {
    height: 100%;
  }
  .footer {
    &__video {
      display: none;
    }
    &--in-view {
      .footer__video {
        display: block;
      }
    }
  }
}

.ipad {
  .footer {
    &__video {
      @apply absolute;
    }
  }
}

@screen xs {
  .footer {
    &__content {
      @apply px-4;
    }
  }
}

@screen sm {
  .footer {
    &__content {
      @apply px-6;
    }
  }
}

@screen lg {
  .footer {
    &__content {
      @apply px-8;
    }
  }
}
.view-leaving {
  .footer {
    &__video {
      opacity: 0;
      transition: opacity var(--view-leave-duration) ease-out;
    }
  }
}
</style>
