<template>
  <div class="page-header">
    <div class="titles text-center lg:text-left width-md-sm fade-up">
      <h1 class="heading trajan">
        {{ heading }}
      </h1>
      <h2
        class="subheading italic text-grey-400 leading-normal xs:leading-snug"
      >
        {{ subheading }}
      </h2>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";

const props = defineProps({
  heading: {
    type: String,
    required: true,
  },
  subheading: {
    type: String,
    required: true,
  },
});

const { heading, subheading } = toRefs(props);
</script>

<style scoped lang="scss">
.page-header {
  .titles {
    padding: 3.6rem 0 3rem;
    .heading {
      font-size: 1.35rem;
    }
  }
}
@screen xs {
  .titles {
    .page-header {
      .heading {
        @apply text-2xl;
      }
    }
  }
}
@screen sm {
  .page-header {
    .titles {
      .heading {
        @apply text-3xl;
      }
    }
  }
}
@screen md {
  .page-header {
    .titles {
      padding: 4.2rem 0 3.8rem;
      .heading {
      }
    }
  }
}
@screen lg {
  .page-header {
    .titles {
      padding: 3.5rem 0 3.8rem;
      .heading {
      }
    }
  }
}
@screen xl {
  .page-header {
    .titles {
      .heading {
        font-size: 2rem;
        line-height: 1.3;
      }
      .subheading {
        font-size: 16px;
      }
    }
  }
}
@screen xxl {
  .page-header {
    .titles {
      padding: 4.2rem 0 4.2rem;
      .heading {
        font-size: 43px;
        line-height: 1.35;
      }
      .subheading {
        font-size: 18px;
      }
    }
  }
}
</style>
